import { lazy } from 'react-router-guard'
import { AgentGuard } from 'helpers'

export default [
  {
    name: 'Mis Productos',
    path: '/mis-productos',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../agent')),
  },
  {
    name: 'Mis Solicitudes Productos',
    path: '/mis-solicitudes/:id',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../agent/ShowProductRequest')),
  },
  {
    name: 'Mis Solicitudes Devoluciones',
    path: '/mis-solicitudes/devolucion/:id',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../agent/ShowProductReturnRequest')),
  },
  {
    name: 'Mis Citas',
    path: '/citas/:id',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../Meetings/Show')),
  },
  {
    name: 'Gestionar Venta',
    path: '/citas/:id/gestionar-venta',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../Meetings/ManageSale')),
  },
  {
    name: 'Detalle de Cita',
    path: '/mis-citas',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../agent/MyMeetings')),
  },
  {
    name: 'Detalle de mis Referidos',
    path: '/mis-referidos/:id',
    exact: true,
    component: lazy(() => import('../Referral/Show')),
  },
  {
    name: 'Pago exitoso',
    path: '/pay-success/:id',
    exact: true,
    component: lazy(() => import('../components/PaySuccess')),
  },
  {
    name: 'Pago rechazado',
    path: '/pay-error',
    exact: true,
    component: lazy(() => import('../components/PayError')),
  },
  {
    name: 'Mis Ventas',
    path: '/mis-ventas',
    exact: true,
    canActivate: [AgentGuard],
    component: lazy(() => import('../agent/MySales')),
  },
  {
    name: 'Detalle Venta',
    path: '/mis-ventas/:id',
    exact: true,
    component: lazy(() => import('../agent/MySales/Show')),
  },
]
