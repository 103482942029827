import { lazy } from 'react-router-guard'

const PublicModule = [
  {
    name: 'Iniciar Sesion',
    path: '/iniciar-sesion',
    exact: true,
    component: lazy(() => import('./login')),
  },
  {
    name: 'Recuperar Contraseña',
    path: '/recuperar-clave',
    exact: true,
    component: lazy(() => import('./recover-password')),
  },
  {
    name: 'Restaurar Contraseña',
    path: '/restaurar-clave',
    exact: true,
    component: lazy(() => import('./restore-password')),
  },
  {
    name: 'No Encontrado',
    path: '/no-encontrado',
    exact: true,
    component: lazy(() => import('./not-found')),
  },
]

export default PublicModule
