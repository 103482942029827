import { lazy } from 'react-router-guard'
import {
  ProductsGuard,
  GerenciaGuard,
  WarehouseGuard,
  UsersRolesGuard,
  DispatchGuard,
  ManagerTelemarketingGuard,
  FinanceGuard
} from 'helpers'

export default [
  {
    name: 'Oficinas',
    path: '/oficinas',
    canActivate: [GerenciaGuard],
    exact: true,
    component: lazy(() => import('../offices')),
  },
  {
    name: 'Maestro de Productos',
    exact: true,
    path: '/maestro-de-productos',
    canActivate: [ProductsGuard],
    component: lazy(() => import('../master')),
  },
  {
    name: 'Categorías',
    exact: true,
    path: '/maestro-de-productos/categorias',
    canActivate: [ProductsGuard],
    component: lazy(() => import('../categories')),
  },
  {
    name: 'Maestro de Insumos',
    exact: true,
    path: '/maestro-de-insumos',
    canActivate: [GerenciaGuard],
    component: lazy(() => import('../supplies')),
  },
  {
    name: 'Categorías',
    exact: true,
    path: '/maestro-de-insumos/categorias',
    canActivate: [GerenciaGuard],
    component: lazy(() => import('../categoriesSupplies')),
  },
  {
    name: 'Usuarios y roles',
    exact: true,
    path: '/usuarios-roles',
    canActivate: [UsersRolesGuard],
    component: lazy(() => import('../UsersRoles')),
  },
  {
    name: 'Bodegas',
    exact: true,
    path: '/bodegas',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse')),
  },
  {
    name: 'Bodegas Show',
    exact: true,
    path: '/bodegas/:id',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse/Show')),
  },
  {
    name: 'Bodegas Show Product Request',
    exact: true,
    path: '/solicitudes/:id',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse/ShowProductRequest')),
  },
  {
    name: 'Bodegas Show Product Return Request',
    exact: true,
    path: '/solicitudes/devolucion/:id',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse/ShowProductReturnRequest')),
  },
  {
    name: 'Bodegas Show Interbodegas Request',
    exact: true,
    path: '/solicitudes/interbodega/:id',
    canActivate: [WarehouseGuard],
    component: lazy(() => import('../warehouse/ShowInterbodegaRequest')),
  },
  {
    name: 'Ventas',
    path: '/ventas',
    exact: true,
    canActivate: [UsersRolesGuard],
    component: lazy(() => import('../sales')),
  },
  {
    name: 'Detalle Ventas',
    path: '/ventas/:id',
    exact: true,
    canActivate: [UsersRolesGuard],
    component: lazy(() => import('../sales/show')),
  },
  {
    name: 'Venta Directa',
    path: '/venta-directa',
    exact: true,
    canActivate: [UsersRolesGuard],
    component: lazy(() => import('../sales/directSale')),
  },
  {
    name: 'Gestionar Venta Directa',
    path: '/venta-directa/:id/gestionar-venta',
    exact: true,
    canActivate: [UsersRolesGuard],
    component: lazy(() => import('../sales/directSale/manageDirectSale')),
  },
  {
    name: 'Solicitudes de Despacho',
    exact: true,
    path: '/solicitudes-de-despacho',
    canActivate: [DispatchGuard],
    component: lazy(() => import('../dispatch')),
  },
  {
    name: 'Gestión de Campañas',
    exact: true,
    path: '/gestion-campañas',
    canActivate: [ManagerTelemarketingGuard],
    component: lazy(() => import('../telemarketingManager/campaignManagement')),
  },
  {
    name: 'Campaña',
    path: '/gestion-campañas/:id',
    exact: true,
    canActivate: [ManagerTelemarketingGuard],
    component: lazy(() => import('../telemarketingManager/campaignManagement/show')),
  },
  {
    name: 'Referidos',
    exact: true,
    path: '/gestion-referidos',
    canActivate: [ManagerTelemarketingGuard],
    component: lazy(() => import('../telemarketingManager/referralManagement')),
  },
  {
    name: 'Detalle de Referido',
    exact: true,
    path: '/gestion-referidos/:id',
    canActivate: [ManagerTelemarketingGuard],
    component: lazy(() => import('../Referral/managementShow')),
  },
  {
    name: 'Gestión de Citas',
    exact: true,
    path: '/gestion-de-citas',
    canActivate: [ManagerTelemarketingGuard],
    component: lazy(() => import('../telemarketingManager/appointmentManagement')),
  },
  {
    name: 'Ventas',
    exact: true,
    path: '/finanzas-ventas',
    canActivate: [FinanceGuard],
    component: lazy(() => import('../finance/sales')),
  },
  {
    name: 'Detalle Venta',
    exact: true,
    path: '/finanzas-ventas/:id',
    canActivate: [FinanceGuard],
    component: lazy(() => import('../finance/sales/show')),
  },
  {
    name: 'Gestión de Pagos',
    exact: true,
    path: '/finanzas-gestion-pagos',
    canActivate: [FinanceGuard],
    component: lazy(() => import('../finance/payments')),
  },
  {
    name: 'Detalle de Comisión',
    exact: true,
    path: '/finanzas-gestion-pagos/:id',
    canActivate: [FinanceGuard],
    component: lazy(() => import('../finance/payments/show')),
  },
]
