import { lazy } from 'react-router-guard'
import { TelemarketingGuard } from 'helpers'

export default [
  {
    name: 'Gestión de citas',
    path: '/gestion-citas/',
    exact: true,
    canActivate: [TelemarketingGuard],
    component: lazy(() => import('../Telemarketing/Agent')),
  },
]
