import { lazy } from 'react-router-guard'
import { ReferredGuard } from 'helpers'

import managementRoutes from './managementRoutes'
import agentRoutes from './agentRoutes'
import telemarketingRoutes from './telemarketingRoutes'

const PrivateModule = [
  {
    name: 'Inicio',
    path: '/',
    exact: true,
    component: lazy(() => import('../home')),
  },
  {
    name: 'Cambiar Contrasena',
    path: '/cambiar-contrasena',
    exact: true,
    component: lazy(() => import('../currentUser/passwordChange')),
  },
  {
    name: 'Mis Referidos',
    path: '/mis-referidos',
    exact: true,
    canActivate: [ReferredGuard],
    component: lazy(() => import('../Referral')),
  },
  {
    name: 'Detalle de Referidos',
    path: '/referidos/:id',
    exact: true,
    component: lazy(() => import('../Referral/Show')),
  },
  ...managementRoutes,
  ...agentRoutes,
  ...telemarketingRoutes,
]

export default PrivateModule
