import jwtDecode from 'jwt-decode'

const AuthGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { pathname } = window.location

    if (token) {
      const { name, roles } = jwtDecode(token)
      if (name && roles) resolve(true)
    }

    const redirect =
      pathname !== '/'
        ? `/iniciar-sesion?redirect=${pathname}`
        : '/iniciar-sesion'

    reject(new Error(redirect))
  })
}

const ProductsGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name === 'gerencia' || role.name === 'bodeguero'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const UsersRolesGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name === 'gerencia' || role.name === 'gerente_oficina'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const WarehouseGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) =>
        role.name === 'gerencia' ||
        role.name === 'bodeguero' ||
        role.name === 'gerente_oficina'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const ReferredGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name !== 'ejecutivo_telemarketing'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const GerenciaGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some((role) => role.name === 'gerencia')

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const AgentGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name === 'gerencia' || role.name === 'vendedor'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const TelemarketingGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name === 'ejecutivo_telemarketing'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const DispatchGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name === 'bodeguero'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const ManagerTelemarketingGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) => role.name === 'gerente_marketing'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

const FinanceGuard = () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('token')
    const { roles } = jwtDecode(token)

    const canView = roles.some(
      (role) =>
        role.name === 'gerente_finanzas' ||
        role.name === 'ejecutivo_finanzas' ||
        role.name === 'gerencia'
    )

    if (canView) resolve(true)
    reject(new Error('/'))
  })
}

export {
  AuthGuard,
  ProductsGuard,
  GerenciaGuard,
  WarehouseGuard,
  UsersRolesGuard,
  ReferredGuard,
  AgentGuard,
  TelemarketingGuard,
  DispatchGuard,
  ManagerTelemarketingGuard,
  FinanceGuard
}
